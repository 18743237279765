'use client'

import { FacetAttribute, Refinement, SortByAttribute, SortingOption } from '@hermes/api-sdk'
import { useBookSearch, useBookSearchRefinements, useBookSearchSortBy } from '@hermes/search'
import { useLocale } from '@hooks/useLocale'
import { getIndexFromLocale } from '@lib/utils/searchEngineHelpers'
import { usePathname } from 'next/navigation'
import { createParser, parseAsBoolean, useQueryState } from 'nuqs'
import { useEffect, useRef } from 'react'
import { useAnalytics } from './analyticsContext'

const SEARCH_INITIAL_STATE = ''
const DEFAULT_ACTIVE_FILTERS = new Set<Refinement>()
const DEFAULT_SORT_OPTION = { value: SortByAttribute.RELEVANCE }
const FILTER_SEPARATOR = ','
const ATTRIBUTE_VALUE_SEPARATOR = ':'

const parseActiveFilters = createParser<Set<Refinement>>({
  parse(queryValue: string): Set<Refinement> {
    if (!queryValue?.trim()) return new Set(DEFAULT_ACTIVE_FILTERS.values())

    const set = new Set<Refinement>()
    queryValue
      .split(FILTER_SEPARATOR)
      .filter(Boolean)
      .forEach((filter) => {
        const [attribute, value] = filter.split(ATTRIBUTE_VALUE_SEPARATOR).map((part) => part?.trim())
        if (!attribute || !value) return

        set.add({
          value,
          attribute: attribute as FacetAttribute
        })
      })
    return set
  },

  serialize(value: Set<Refinement>): string {
    if (!value?.size) return ''

    return Array.from(value)
      .filter((item) => item.attribute && item.value)
      .map((item) => `${item.attribute}${ATTRIBUTE_VALUE_SEPARATOR}${item.value}`)
      .join(FILTER_SEPARATOR)
  }
})

const parseCurrentSortOption = createParser<SortingOption>({
  parse(queryValue: string): SortingOption {
    return {
      value: SortByAttribute[queryValue as keyof typeof SortByAttribute]
    }
  },

  serialize(value: SortingOption): string {
    return value.value
  }
})

export const SearchToQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname()
  const analytics = useAnalytics()
  const locale = useLocale()
  const searchIndex = getIndexFromLocale(locale)

  const { toggleRefinement, activeRefinements } = useBookSearchRefinements()
  const { query, setQuery, setSemanticEnabled, semanticEnabled } = useBookSearch()
  const { setSorting, currentSortOption } = useBookSearchSortBy()

  const [queryNuqs, setQueryNuqs] = useQueryState(`query`, { defaultValue: '' })
  const [semanticEnabledNuqs, setSemanticEnabledNuqs] = useQueryState(
    'semanticEnabled',
    parseAsBoolean.withDefault(semanticEnabled)
  )
  const [activeRefinementsNuqs, setActiveRefinementsNuqs] = useQueryState(
    `${searchIndex}[refinementList]`,
    parseActiveFilters.withDefault(DEFAULT_ACTIVE_FILTERS)
  )
  const [currentSortOptionNuqs, setCurrentSortOptionNuqs] = useQueryState<SortingOption>(
    `${searchIndex}[sortBy]`,
    parseCurrentSortOption.withDefault(DEFAULT_SORT_OPTION)
  )
  const onSearchPage = pathname?.includes('/search')
  const lastPathNameWhenMounted = useRef('')

  // PARSE - convert URL to nuqs state (runs only once on mount)
  useEffect(() => {
    if (pathname !== lastPathNameWhenMounted.current) {
      if (queryNuqs) {
        setQuery(queryNuqs)
      }
      if (semanticEnabledNuqs) {
        setSemanticEnabled(semanticEnabledNuqs)
      }
      if (activeRefinementsNuqs.size !== activeRefinements.size) {
        activeRefinementsNuqs.forEach((refinement) => {
          toggleRefinement(refinement)
        })
      }
      if (currentSortOptionNuqs.value) {
        setSorting(currentSortOptionNuqs)
      }
      lastPathNameWhenMounted.current = pathname
    }
  }, [activeRefinementsNuqs, queryNuqs])

  // SERIALIZE - convert nuqs to URL
  useEffect(() => {
    if (onSearchPage) {
      setQueryNuqs(query)
      setSemanticEnabledNuqs(semanticEnabled)
      if (activeRefinements) {
        setActiveRefinementsNuqs(activeRefinements)
      }
      if (currentSortOption) {
        setCurrentSortOptionNuqs(currentSortOption)
      }
    }
  }, [query, semanticEnabled, activeRefinements, currentSortOption])

  useEffect(() => {
    if (!pathname.includes('/search')) {
      if (queryNuqs !== SEARCH_INITIAL_STATE) {
        analytics.trackEvent('SEARCH_ENDED', {
          searchQuery: queryNuqs
        })
      }
      setQueryNuqs(SEARCH_INITIAL_STATE)
      setQuery(SEARCH_INITIAL_STATE)
    }
  }, [pathname])

  return <>{children}</>
}
